import { CheckIcon } from "lucide-react";
import * as React from "react";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from "@/components/ui/command";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { useVirtualizer } from "@tanstack/react-virtual";

export const MultiSelectVirtualizedCommand = ({
  filteredOptions,
  handleSearch,
  handleInputKeyDown,
  toggleAll,
  handleSelectAllCheckIcon,
  selectedOptions,
  toggleOption,
  handleClear,
  setIsPopoverOpen,
}) => {
  const parentRef = React.useRef(null);

  const virtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 30,
    overscan: 5,
  });

  return (
    <Command shouldFilter={false}>
      <CommandInput
        placeholder="Buscar..."
        onValueChange={handleSearch}
        onKeyDown={handleInputKeyDown}
      />

      <CommandGroup
        className="max-h-96 h-auto overflow-auto w-full"
        ref={parentRef}
      >
        <CommandEmpty>Nenhum resultado.</CommandEmpty>

        {virtualizer.getVirtualItems().length > 0 ? (
          <CommandItem
            key="all"
            onSelect={toggleAll}
            className="cursor-pointer"
          >
            <div
              className={cn(
                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                handleSelectAllCheckIcon()
                  ? "bg-primary text-primary-foreground"
                  : "opacity-50 [&_svg]:invisible"
              )}
            >
              <CheckIcon className="h-4 w-4" />
            </div>
            <span>Selecionar todos</span>
          </CommandItem>
        ) : (
          <CommandItem>Nenhum resultado</CommandItem>
        )}

        <div
          className="w-full relative"
          style={{
            height: `${virtualizer.getTotalSize()}px`,
          }}
        >
          {virtualizer.getVirtualItems().map((virtualOption) => {
            const isSelected = selectedOptions.find(
              (o) => o.value == filteredOptions[virtualOption.index].value
            );

            return (
              <CommandItem
                key={virtualOption.key}
                onSelect={() =>
                  toggleOption(filteredOptions[virtualOption.index])
                }
                className="absolute inset-y-0 w-full"
                style={{
                  height: `${virtualOption.size}px`,
                  transform: `translateY(${virtualOption.start}px)`,
                }}
              >
                <div
                  className={cn(
                    "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                    isSelected
                      ? "bg-primary text-primary-foreground"
                      : "opacity-50 [&_svg]:invisible"
                  )}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>

                {filteredOptions[virtualOption.index].icon
                  ? React.createElement(
                      filteredOptions[virtualOption.index]
                        ?.icon as React.ComponentType<{
                        className?: string;
                      }>,
                      {
                        className: "mr-1 h-4 w-4 text-muted-foreground",
                      }
                    )
                  : null}
                <span>{filteredOptions[virtualOption.index].label}</span>
              </CommandItem>
            );
          })}
        </div>
      </CommandGroup>
      <CommandSeparator />
      <CommandGroup>
        <div className="flex items-center justify-between">
          {selectedOptions.length > 0 && (
            <>
              <CommandItem
                onSelect={handleClear}
                className="flex-1 justify-center cursor-pointer font-normal"
              >
                Limpar
              </CommandItem>
              <Separator
                orientation="vertical"
                className="flex min-h-6 h-full"
              />
            </>
          )}
          <CommandItem
            onSelect={() => setIsPopoverOpen(false)}
            className="flex-1 justify-center cursor-pointer max-w-full"
          >
            Fechar
          </CommandItem>
        </div>
      </CommandGroup>
    </Command>
  );
};
