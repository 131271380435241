import React from "react";

interface AdministrativeAreaDataTableFilteredRowsContextProps {
  filteredRows: any[];
  setFilteredRows: React.Dispatch<React.SetStateAction<any[]>>;
}

export const AdministrativeAreaDataTableFilteredRowsContext =
  React.createContext<AdministrativeAreaDataTableFilteredRowsContextProps>({
    filteredRows: [],
    setFilteredRows: () => {},
  });
