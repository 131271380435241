import axios, { AxiosInstance } from "axios";
import { USER_TOKEN } from "../constants";

type ApiNames = "admin" | "reports" | "pbi" | null;

export const getApi = async (
  apiName: ApiNames = null
): Promise<AxiosInstance> => {
  const token = localStorage.getItem(USER_TOKEN) || "";
  let baseURL = "";

  switch (apiName) {
    case "admin":
      baseURL = import.meta.env.VITE_API_HOST_ADMIN;
      break;
    case "reports":
      baseURL = import.meta.env.VITE_API_HOST_REPORTS;
      break;
    case "pbi":
      baseURL = import.meta.env.VITE_POWER_BI_HOST;
      break;
    default:
      baseURL = import.meta.env.VITE_API_HOST;
  }

  const api = axios.create({
    baseURL,
  });

  api.interceptors.request.use(
    (config: any) => {
      if (apiName !== "pbi") {
        config.headers.Authorization = "Bearer " + JSON.parse(token).access;

        if (config.url && config.url.endsWith("query_v2")) {
          const isProduction = import.meta.env.MODE === "production";

          if (isProduction) {
            if (config.data) {
              config.data = {
                ...config.data,
                logPlatform: "web",
              };
            } else {
              config.data = { logPlatform: "web" };
            }
          }
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem(USER_TOKEN);
        window.location.reload();
      } else {
        return Promise.reject(error);
      }
    }
  );

  return api;
};
