import { useQuery } from "react-query";

import {
  CompanyBranchProps,
  CompanyModules,
  CompanyProps,
} from "@/types/company";
import { getApi } from ".";

export const useQueryListUserCompanies = (options: object) =>
  useQuery<Array<CompanyProps>>(
    ["queryListUserCompanies"],
    async () => {
      const api = await getApi();

      return api.get("/companies").then((result) => result.data);
    },
    options
  );

export const useQueryCompanyBranchFilter = (
  companyId: number,
  reportId: string,
  options: object
) =>
  useQuery<Array<CompanyBranchProps>>(
    ["queryCompanyBranchFilter", reportId, companyId],
    async () => {
      const api = await getApi();

      return api
        .post("/mobile/report/query", {
          companyId,
          report: reportId,
        })
        .then((result) => result.data.dados);
    },
    options
  );

export const useQueryGetCompaniesModules = (
  companyId: number,
  options: object
) =>
  useQuery<CompanyModules[]>(
    ["queryGetCompaniesModules", companyId],
    async () => {
      const api = await getApi();

      return api
        .get(`/modules-companies?company_id=${companyId}`)
        .then((result) => result.data.results);
    },
    options
  );
