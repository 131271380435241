import { ForwardedRef, forwardRef, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  LicenseManager,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-enterprise";

import { AG_GRID_LOCALE_BR } from "./locale";
import { GoalInformation } from "@/types/reports/sales/goalTracking";
import { ColumnConfig } from "@/types/agGrid";
import { SaleInformation } from "@/types/reports/salesAnalysis";
import { cn } from "@/lib/utils";

LicenseManager.setLicenseKey(import.meta.env.VITE_AGGRID_TOKEN);

export type AutoSizeStrategyProps =
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy;
interface AgGridBaseProps {
  groupDefaultExpanded?: number;
  filteredReportData?: GoalInformation[] | SaleInformation[];
  columnDefs: ColDef[];
  className?: string;
  rowSelection?: "single" | "multiple";
  rowGroupPanelShow?: "always" | "onlyWhenGrouping" | "never";
  autoSizeStrategy?: AutoSizeStrategyProps;
  rowHeight?: number;
  headerHeight?: number;
  sidebarDefs?: any;
  groupColumnDefs?: ColumnConfig;
  isPivotMode?: boolean;
  defaultColumnDefs?: ColDef;
  handleReportData?: (columns: []) => void;
  handleChangeFilter?: () => void;
  onRowDoubleClicked?: (opts: any) => void;
  onRowClicked?: (opts: any) => void;
  onCellDoubleClicked?: (opts: any) => void;
  onCellValueChanged?: (opts: any) => void;
  groupAllowUnbalanced?: boolean;
  gridOptions?: any;
}

export const AgGridBase = forwardRef(
  (
    {
      filteredReportData,
      columnDefs,
      className,
      rowHeight = 30,
      headerHeight = 40,
      rowSelection = "multiple",
      rowGroupPanelShow = "always",
      gridOptions,
      autoSizeStrategy = { type: "fitCellContents" },
      groupAllowUnbalanced,
      isPivotMode,
      sidebarDefs,
      defaultColumnDefs,
      handleReportData,
      handleChangeFilter,
      groupDefaultExpanded,
      groupColumnDefs,
      onRowDoubleClicked,
      onRowClicked,
      onCellDoubleClicked,
      onCellValueChanged,
    }: AgGridBaseProps,
    ref: ForwardedRef<AgGridReact>
  ) => {
    const columnDefinitions: ColDef = useMemo(() => {
      return {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true,
        enableRowGroup: true,
        ...defaultColumnDefs,
      };
    }, [defaultColumnDefs]);

    const sidebarDefinitions = useMemo(() => {
      return {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
            },
          },
        ],
        ...sidebarDefs,
      };
    }, [sidebarDefs]);

    const onSelectionChanged = useCallback(() => {
      if (ref && "current" in ref && ref.current) {
        const selectedRows = ref.current!.api.getSelectedRows();

        handleReportData && handleReportData(selectedRows as []);
      }
    }, [ref, handleReportData]);

    return (
      <AgGridReact
        ref={ref}
        className={cn("ag-theme-quartz font-light", className)}
        rowHeight={rowHeight}
        rowData={filteredReportData}
        columnDefs={columnDefs}
        defaultColDef={columnDefinitions}
        onFilterChanged={handleChangeFilter}
        localeText={AG_GRID_LOCALE_BR}
        headerHeight={headerHeight}
        rowGroupPanelShow={rowGroupPanelShow}
        sideBar={sidebarDefinitions}
        rowSelection={rowSelection}
        rowMultiSelectWithClick={true}
        autoGroupColumnDef={{ ...groupColumnDefs }}
        quickFilterText=""
        onSelectionChanged={onSelectionChanged}
        groupDefaultExpanded={groupDefaultExpanded}
        pivotMode={isPivotMode}
        suppressAggFuncInHeader={true}
        groupAllowUnbalanced={groupAllowUnbalanced}
        onRowDoubleClicked={onRowDoubleClicked}
        onRowClicked={onRowClicked}
        onCellDoubleClicked={onCellDoubleClicked}
        onCellValueChanged={onCellValueChanged}
        autoSizeStrategy={autoSizeStrategy}
        gridOptions={{
          grandTotalRow: "bottom",
          groupSuppressBlankHeader: true,
          tooltipShowDelay: 50,
          autoSizeStrategy: {
            type: "fitCellContents",
          },
          statusBar: {
            statusPanels: [
              {
                statusPanel: "agTotalRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agFilteredRowCountComponent",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
              },
              {
                statusPanel: "agAggregationComponent",
              },
            ],
          },
          ...gridOptions,
        }}
      />
    );
  }
);
