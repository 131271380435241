import { SESSION_COMPANY } from "@/constants";
import { CompanyProps } from "@/types/company";
import { create } from "zustand";

interface CompanyStore {
  company: CompanyProps;
  setCompany: (company: CompanyProps) => void;
}

export const emptyCompany: CompanyProps = {
  id: 0,
  central_trocas: "",
  logo: "",
  nome_fantasia: "",
  database: "",
  modules: [],
};

export const useCompanyStore = create<CompanyStore>((set) => ({
  company:
    typeof window !== "undefined" && sessionStorage.getItem(SESSION_COMPANY)
      ? JSON.parse(sessionStorage.getItem(SESSION_COMPANY)!)
      : emptyCompany,
  setCompany: (company) => {
    set({ company });
    if (typeof window !== "undefined") {
      sessionStorage.setItem(SESSION_COMPANY, JSON.stringify(company));
    }
  },
}));
